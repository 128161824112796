import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { breakpoints, Button, Image } from '@ecommerce/shared'
import { Icon } from '../components/Icon/Icon'
import { BackButton } from '../components/Checkout/components/Layout'
import Layout from '../components/Layout/LayoutStaticPage'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.medium};
  min-height: calc(100vh - 213px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (${breakpoints.tabletPortrait.max}) {
    background: ${({ theme }) => theme.colors.white};
  }

  @media screen and (${breakpoints.tabletLandscape.min}) and (${breakpoints.tabletLandscape.max}) {
    min-height: calc(100vh - 195px);
  }
`

export const Inner = styled.div`
  width: 723px;
  padding: 34px 92px;
  margin: 50px 0;
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};

  h1 {
    font-size: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};

    @media screen and (${breakpoints.phoneLandscape.max}) {
      &.login-title {
        display: none;
      }
    }
  }

  img {
    width: 230px;
    margin: 25px auto;
    display: block;
  }

  @media screen and (${breakpoints.tabletPortrait.max}) {
    width: 100%;
    padding: 5px;
    margin: 0;
    box-shadow: none;
  }

  @media screen and (${breakpoints.tabletLandscape.min}) and (${breakpoints.tabletLandscape.max}) {
    width: 500px;
    padding: 28px 0;
  }

  @media screen and (${breakpoints.desktop.min}) {
    .back-button {
      top: 50px;
      left: 40px;
    }
  }
`

const Buttons = styled.div`
  width: 220px;
  margin: auto;

  button {
    width: 100%;
    margin-bottom: 18px;

    &.prehome-button {
      background: none;
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
`

const ActivatedAccountPage = ({
  pageContext: {
    currentCity: { slug },
  },
}: PgPageProps) => (
  <Layout title="Cuenta Activada Exitosamente">
    <Wrapper>
      <Inner className="ActivatedAccount__">
        <BackButton className="back-button" onClick={() => navigate(-1)}>
          <Icon iconId="arrow_left" />
          Volver
        </BackButton>
        <h1>¡Cuenta activada con éxito!</h1>
        <Image
          preload={false}
          src="https://images.ctfassets.net/16npdkkoi5mj/20L1fcjZKFGGLJclR2aSN3/28750a34ba00ca2d1ca4a52fce35cfe7/icon-account-created-success.svg?h=250"
          alt="account-created-successfully"
        />
        <Buttons>
          <Button onClick={() => navigate(`/${slug}/login`)}>Inicia sesión</Button>
          <Button className="prehome-button" onClick={() => navigate(`/${slug}`)}>
            Ir al inicio
          </Button>
        </Buttons>
      </Inner>
    </Wrapper>
  </Layout>
)

export default withPageTransition(ActivatedAccountPage)
